#notifications {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    right: -350px;
    width: 350px;
    transition: right 200ms;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 50px;
}
#notifications.open {
    right: 0;
}

.notifications-header {
    background-color: var(--itb-green);
    padding: 10px 10px 10px 10px;
    font-size: 18px;
    font-weight: bold;
    color: var(--light-gray);
    border-radius: 2px;
}

.delete-all-notifications {
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    padding-top: 10px;
    padding-bottom: 5px;
    margin-right: auto;
}

.delete-all-notifications span:hover {
    cursor: pointer;
    text-decoration-line: underline;
    font-weight: bold;
}

.notification {
    position: relative;
    background-color: var(--light-gray);
    padding: 15px 10px 10px 10px;
    min-height: 100px;
    border-bottom: 2px solid var(--itb-green);
    text-align: left;
    user-select: none;
    font-size: 14px;
    right: 0;
    transition: right 200ms;
    border-left: 4px solid var(--itb-green);
    border-right: 2px solid var(--itb-green);
    border-radius: 5px;
}
.notification.removed {
    right: -800px;
}

.notification-content{
    cursor: pointer;
}

.notification:hover {
    background-color: var(--light-gray)
}

.notification svg {
    position: absolute;
    top: 6px;
    right: 8px;
    fill: var(--gray);
    cursor: pointer;
    z-index: 6;
}
.notification pre {
    white-space: pre-wrap;
    word-break: break-word;
}


.no-notification {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}