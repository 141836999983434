#app-permissions-page {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    flex-wrap: wrap;
    width: fit-content;
}

#app-permissions-list, #app-permissions-config{
    padding: 20px;
    border-radius: 4px;
    border: 1px solid var(--light-gray);
    margin: 8px;
}

#app-permissions-config{
    height: fit-content;
    padding-bottom: 40px;
    flex: 1;
    max-width: 842px;
}

.app-permissions-actions .action {
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    bottom: 8px;
    margin: 0 4px;
    cursor: pointer;
    height: 38px;
    border-radius: 6px;
    padding: 2px;
}

.app-permissions-actions .action label {
    font-size: 10px;
    display: block;
}

.app-permissions-actions .action:hover {
    box-shadow: var(--default-shadow);
}
.app-permissions-actions .action:hover svg {
    width:20px;
    height:20px;
}

.app-permissions-actions .action.approve svg {
    fill: var(--itb-green);
}

.app-permissions-actions .action.reprove svg {
    transform: rotate(180deg);
    fill: var(--error-color);
}

.app-permissions-actions .action.permissions svg {
    fill: var(--text-color);
}

#edit-default-permissions {
    position: relative;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid var(--light-gray);
    text-align: left;
    min-width: 430px;
    cursor: pointer;
}

#edit-default-permissions:hover {
    background-color: #fefffe;
}

#edit-default-permissions * {
    user-select: none;
}

#edit-default-permissions label {
    font-size: 18px;
    font-weight: bold;
    display: block;
    pointer-events: none;
}

#edit-default-permissions span {
    font-size: 11px;
}
#edit-default-permissions svg {
    position: absolute;
    right: 20px;
    top: 24px;
}

#app-permissions-edit-page {
    min-width: 750px;
    max-width: 1200px;
    margin: 0 auto;
}

#app-permissions-edit-page .subtitle{
    text-align: left;
}

#app-permissions-edit-page form {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px;
}

#app-permissions-edit-page section{
    padding: 50px 20px 10px 20px;
    border-radius: 4px;
    border: 1px solid var(--light-gray);
    min-width: 350px;
    min-width: 350px;
    margin: 8px;
    position: relative;
    flex-grow: 1;
}

#app-permissions-edit-page section label.section-title{
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 18px;
    font-weight: bold;
}

.app-auto-approve {
    position: relative;
}

.app-auto-approve .info-popup {
    position: absolute;
    left: 140px;
    top: 3px;
    width: 300px;
}