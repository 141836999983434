#download-apps {
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
}

#download-apps .info-text {
    margin: 20px auto;
    line-height: 1.75rem;
}

#download-apps img {
    display: block;
}

#windows-logo {
    width: 200px !important;
}

#linux-logo {
    width: 150px;
}

.download-button {
    box-shadow: var(--default-shadow);
    height: 80px;
    width: 280px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid lightgray;
    background-color: white;
    cursor: pointer;
}

.download-button:hover {
    background-color: #eee;
}