.select-search {
    position: relative;
    z-index: 5;
}

.select-search svg {
    fill: var(--dark-gray);
    position: absolute;
    right: 20px;
    top: 20px;
}

.select-search-options {
    position: absolute;
    top: 49px;
    margin: 0 10px;
    border: 1px solid #cfd8dc;
    border-radius: 4px;
    width: calc(100% - 20px);
    background-color: white;
    z-index: 2;
}

.select-search-options span {
    display: block;
    padding: 5px 20px;
}

.select-search-options span:hover {
    background-color: var(--light-gray);
    cursor: pointer;
}
