#maintenance-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}
#maintenance-page svg{
    fill: var(--gray);
    width: 400px;
}