.itb-input-center {
  padding-top: 20px;
  width: 100%; 
  max-width: 300px; 
}
.customize-flex-container {
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.customize-ip {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px; /* Espaço entre os elementos */
}

.upload-fw-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
