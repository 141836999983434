#devices-page {
    max-width: 1400px;
    margin: 0 auto;
}

.device-details {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    min-width: 35%;
    background-color: white;
    animation: slide-right 200ms ease;
    z-index: 3;
    padding: 20px;
    background-color: #fbfbfb;
    overflow-y: auto;
}

.device-details .router-button {
    max-width: 300px;
    margin: 10px auto 20px auto;
}

.device-details .profile,
.device-apply-button,
.device-remove-associate-container {
    max-width: 324px;
    margin: 0 auto;
}

.device-details .profile {
    margin-top: 30px;
}

.device-details .select {
    max-width: 320px;
    margin: 0 auto;
}

.device-details-title {
    font-size: 22px;
    font-weight: bold;
    padding: 10px 5px;
    border-bottom: 1px solid var(--light-gray);
    margin-bottom: 20px;
}

#device-details-waiting,
#device-details-error {
    text-align: left;
    margin: 0 auto;
    max-width: 500px;
    padding-bottom: 40px;
}

#device-details-waiting .subtitle,
#device-details-error .subtitle {
    text-align: center;
}

#device-details-waiting li,
#device-details-error li {
    padding: 5px 0;
}

#device-details-waiting svg,
#device-details-error svg {
    display: block;
    margin: 30px auto;
}

.device-details-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2;
    animation: slowly-dark 200ms ease;
}

@keyframes slide-right {
    from {
        right: -40%;
    }

    to {
        right: 0;
    }
}

@keyframes slowly-dark {
    from {
        background-color: transparent;
    }

    to {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

.device-filter {
    display: flex;
    width: 100%;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
}

.device-filter .itb-input {
    margin-top: 10px;
}

.device-filter .itb-button-container {
    width: 140px;
}

.device-filter .itb-button-md {
    min-width: 130px;
}

.itb-button-container #btn-discovery {
    width: 20px;
}

.discovery {
    width: 260px !important;
    padding-left: 10px;
}

.device-details-close {
    fill: var(--gray);
    position: absolute;
    top: 10px;
    left: 10px;
}


#devices-page.with-pager {
    padding-bottom: 60px !important;
}

.device-status {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    padding: 0;
}

.device-status-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
}

.device-status-icon.online {
    background-color: var(--light-green);
}

.device-status-icon.offline {
    background-color: var(--error-color);
    animation: error-attention 1.5s ease-in-out infinite;
}

.device-status-icon.waiting {
    background-color: goldenrod;
}

.device-status-icon.error {
    background-color: red;
}

.device-status-icon.updating {
    background-color: blue;
    animation: updating-attention 1.5s ease-in-out infinite;
}

.device-status-info {
    padding: 0px 8px 5px 8px;
}

.device-status-info svg {
    position: relative;
    top: 3px;
    fill: #92a4b1;
}

.device-status-info:hover svg {
    fill: lime;
}

.device-status .loading {
    justify-content: flex-start;
    bottom: 4px;
}

.device-status .info-popup {
    position: relative;
    top: 5px;
    left: 10px;
}

@keyframes error-attention {
    0% {
        background-color: var(--error-color);
    }

    50% {
        background-color: red;
        box-shadow: 0 0 1px 1px red;
    }

    100% {
        background-color: var(--error-color);
    }
}

@keyframes updating-attention {
    0% {
        background-color: blue;
    }

    50% {
        background-color: steelblue;
        box-shadow: 0 0 1px 1px steelblue;
    }

    100% {
        background-color: blue;
    }
}

#device-associator-container {
    position: fixed;
    width: 800px;
    height: 560px;
    z-index: 10;
    top: calc(50% - 280px);
    left: calc(50% - 400px);
    background-color: white;
    padding: 30px;
    border-radius: 6px;
    text-align: left;
    display: flex;
    margin: 0 auto;
}

#device-associator-container .close {
    position: absolute;
    top: 15px;
    right: 15px;
    fill: var(--text-color);
    cursor: pointer;
}

#device-associator {
    min-width: 250px;
    position: relative;
    top: 4px;
}

.device-associator-form {
    border-right: 2px solid var(--gray);
    padding-right: 20px;
    max-width: 400px;
}

.device-associator-list {
    width: 400px;
    padding-left: 20px;
}

#device-associator .input-add {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#device-associator .info-text {
    margin-bottom: 20px;
}

#device-associator .info-text.warning {
    color: goldenrod;
    background-color: #feffde;
    padding: 8px 10px 10px 10px;
    border: 1px solid goldenrod;
    border-radius: 6px;
}

#device-associator .input-add .itb-button-md {
    min-width: 130px;
    max-width: 130px;
    height: 48px;
    position: relative;
    bottom: 2px;
}

#device-associator .input-add .itb-button-container {
    min-width: 140px;
    max-width: 140px;
}

#device-associator .input-add .itb-input-field {
    max-width: 500px;
}

#device-associator .category-select {
    position: relative;
    display: flex;
    padding: 30px 10px 10px 10px;
}

#device-associator .category-select span {
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    top: 5px;
    left: 15px;
}

#device-associator .category-select .radio-group {
    margin-bottom: 5px;
}

#device-details-container {
    width: 320px;
    margin: 0 auto;
}

#devices-page-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    flex-wrap: wrap;
    padding-bottom: 32px;
}

.mac-entry {
    position: relative;
}

.mac-entry .del-icon {
    fill: var(--gray);
    position: absolute;
    padding: 5px;
    right: 5px;
    top: -5px;
    cursor: pointer;
}

#manage-button {
    max-width: 300px;
    background-color: var(--itb-green);
    border-radius: 4px;
    height: 40px;
    font-size: 15px;
}

#manage-button * {
    color: white;
    fill: white;
}

.no-managed {
    max-width: 400px;
    text-align: left;
    margin: 30px auto !important;
}

.device-list-profile {
    display: flex;
    height: 17px;
}

.device-list-profile .loading {
    position: relative;
    top: 5px;
    margin-left: 5px;
}

.profile-status {
    display: flex;
    position: relative;
    bottom: 10px;
}

.profile-status .loading {
    position: relative;
    top: 5px;
    margin-left: 10px;
}

.assoc-list-container {
    max-height: calc(100% - 60px);
    overflow-y: auto;
}

.open-file {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.file-name {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    padding: 1rem;
}

.import-btn {
    display: flex;
    justify-content: flex-end;
    width: 50%;

}

.filename-label {
    margin-bottom: 0px;
}

.profile-select .select-search-options {
    max-height: 300px;
    overflow-y: auto;
}

.download-model {
    padding-right: 15px;
    padding-top: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.download-link {
    color: #00a335;
    text-decoration: none;
}