.login-page {
    width: 100%;
    height: 100%;
    text-align: center;
}

#login-form-container {
    position: relative;
    width: 350px;
    margin: 0 auto;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px 20px;
    top: -20px;
}

#login-form {
    position: relative;
    top: -30px;
}

#header-big img {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100px;
}

#login-remotize-logo {
    position: absolute;
    top: 70px;
    width: 100%;
}

.login-welcome {
    font-size: 18px;
    padding: 10px 0px 20px 0;
}

.login-content {
    border-top: 1px solid #ddd;
    padding: 20px 20px 40px 20px;
    text-align: left;
}

.google-button {
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 60px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .12);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 278px;
    padding: 18px 15px;
    text-decoration: none;
    color: grey;
    text-align: left;
    font-size: 19px;
    margin: 20px 0;
    cursor: pointer;
}

.google-button img {
    max-width: 35px;
    height: 35px;
    margin: 0;
    position: relative;
    top: 0px;
}

.google-button span {
    padding-left: 20px;
    position: relative;
    bottom: 2px;
}

#login-faq {
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
}