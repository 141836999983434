.info-line {
    display: flex;
    margin: 10px 0;
}
.info-line label {
    flex:1;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    margin-right: 2px;
    white-space: nowrap;
}
.info-line span {
    flex: 1;
    text-align: left;
    font-size: 16px;
    margin-left: 2px;
    padding: 1px 0 0 0;
    overflow-wrap: anywhere;
}
