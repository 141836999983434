#lgpd-admin-page section{
    max-width: 400px;
    margin: 0 auto;
    padding: 20px 0;
}

#lgpd-consent-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 0;
    text-align: justify;
}
#lgpd-consent-page .subtitle {
    text-align: center;
    margin-bottom: 20px;
}

#lgpd-consent-page form{
    max-width: 570px;
    margin: 40px auto;
    text-align: center;
}

.center-link{
    padding: 20px 0px 0px 0px;
    width: 100%;
    text-align: center;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.center-link.inline {
    display: inline;
    margin: 0 4px;
    padding: 0;
}

.lgpd-label {
    position: absolute;
    top: -3px;
    width: 330px;
    line-height: 1.5rem;
}
