#diagram {
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
}

#diagram .info-text {
    margin: 20px auto;
    line-height: 1.75rem;
}

#diagram img {
    display: block;
}