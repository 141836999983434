.toggle{
    position: relative;
    width: 40px;
    height: 14px;
    cursor: pointer;
}
.toggle.is-true .toggle-rail {
    opacity: 0.24;
    background-color: var(--itb-green);
}
.toggle.is-true .toggle-circle {
    left: 20px !important
}
.toggle.is-true .toggle-circle {
    background-color: var(--itb-green);
}

.toggle-rail {
    position: absolute;
    left: 2px;
    width: 34px;
    height: 14px;
    border-radius: 25px;
    background-color: rgba(120, 144, 156, 0.26);
}

.toggle-circle {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #78909c;
    position: absolute;
    top: -3px;
    left: 0;
    transition: left 200ms ease-in-out;
}

.toggle-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle-group.full-width {
    border-width: 1px 0;
    border-style: solid;
    border-color: #dfe2e6;
    height: 80px;
    margin-bottom: -1px;
    width: calc(100%);
}

.toggle-group.full-width.large {
    height: 96px;
    padding: 0 24px;
}

.toggle-group.full-width.large .label-group > p {
    margin-top: 5px;
}

.toggle-group.full-width.large .label-group > p {
    margin-top: 5px;
}

.toggle-group label {
    font-size: 17px;
    color: #1e212d;
}

.toggle-group.disabled * {
    cursor: not-allowed;
}
.toggle-group.disabled .toggle-circle {
    background-color: var(--medium-gray);
}
.toggle-group.disabled *, .toggle-group.disabled label::before, .toggle-group.disabled input[type=radio]:checked + label:before {
    color: var(--medium-gray);
    fill: var(--medium-gray);
    border-color: var(--medium-gray);
}
.toggle-group.disabled input[type=radio]:checked + label:before {
    background-color: var(--medium-gray);
}

.label-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.label-group p {
    margin: 0;
    color: #78909c;
    font-size: 16px;
}

.label-container {
    display: flex;
}

.label-container .info-popup {
    margin-left: 8px;
}