#main-app {
    width: 100%;
    transition: padding-top 100ms ease-out;
    padding: 0;
    text-align: center;
    background-color: white;
}

.beta-info {
    position: fixed;
    top: 10px;
    left: 60px;
    color: white;
    width: 70px;
    font-size: 14px;
    z-index: 1;
}

.info-text {
    padding: 5px;
    font-size: 12px;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.main-header-logo img {
    top: -25px;
    height: 75px;
}

.center-link{
    padding: 20px 0px 0px 0px;
    width: 100%;
    text-align: center;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.with-info {
    position: relative;
}
.with-info .info-popup {
    position: absolute;
    top: -2px;
    left: 90%
}

@media screen and (min-width: 500px){
    #with-info .info-popup {
        left: 250px;
    }
}
