.gateway-card {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    width: 50%;
    min-width: 620px;
}
.gateway-page-container {
    display: flex;
    padding: 20px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.model-devices-chart {
    display: flex;
    max-height: 230px;
    padding-left: 100px;
    margin-bottom: 10px;
}
.model-devices-chart-bar {
    display: flex;
    max-height: 230px;
    padding-left: 60px;
    margin-bottom: 40px;
}
.devices-chart-container{
    display: flex;
    justify-content: center;
}
.radial-chart-legend{
    margin-left:30px
}
#radial-stats{
    padding: 20px;
}
#bar-stats{
    padding: 20px;
}

    