#device-management-page {
    padding: 0 20px 20px 20px;
    overflow-x: auto;
}

#device-management-page .tabs-container {
    padding-top: 6px;
}

#device-wan-statistics .chart{
    margin: 0 auto;
    width: fit-content;
}

#device-wan-statistics .series{
    padding: 8px 10px 8px 35px;
    width: fit-content;
    margin: 4px auto;
    border: 1px solid var(--gray);
    border-radius: 6px;
    position: relative;
}
#device-wan-statistics .series.download::before{
    display: inline-block;
    content: '';
    width: 16px;
    height: 16px;
    background-color: darkcyan;
    position: absolute;
    left: 10px;
    bottom: 10px;
}
#device-wan-statistics .series.upload::before{
    display: inline-block;
    content: '';
    width: 16px;
    height: 16px;
    background-color: green;
    position: absolute;
    left: 10px;
    bottom: 10px;
}

#device-wan-statistics .series label{
    display: inline-block;
    font-weight: bold;
    margin-right: 10px;
    width: fit-content;
    min-width: 80px;
    text-align: right;
}

#device-wan-statistics .series span{
    display: inline-block;
    width: 100px;
    text-align: left;
}

.router-device-traffic {
    display: flex;
    position: relative;
}

.router-device-traffic span{
    padding: 0;
    width: 60px;
}
.router-device-traffic .rv-xy-plot{
    position: absolute;
    left: 10px;
    top: -8px;
}

.flex-container {
    display: flex;
}

.dashboard-subtitle {
    fill: var(--gray);
    color: #555;
    display: flex;
    align-items: center;
    padding: 0px 0 10px 20px;
}

.dashboard-subtitle label {
    padding: 2px 0 0 10px;
}

