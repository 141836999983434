.sip-management-page{
    min-width: 1020px;
    max-width: 1500px;
    margin: 0 auto;
}

.sip-flex-container{
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.fxs-sip {
    min-width: 350px;
    margin: 0px 0px 0 0;
}

.sip-ip {
    min-width: 350px;
    margin: 0 150px 0 0;
}
/*IMPORTANTE!! --> Daqui para baixo esta o css que altera a cor dos inputs e checkbox que na V1 estão desabilitados para o usuário não fazer nenhuma configuração*/
.itb-input-field.disabled input{
    cursor: not-allowed;
    color:black;
}

.itb-input-field.disabled label{
    top: -2px;
    color:#00a335;
    font-size: 12px;
}
.checkbox-group.disabled * {
    cursor: not-allowed;
}
.checkbox-group.disabled *, .checkbox-group.disabled .after-input::before, .checkbox-group.disabled input[type=radio]:checked + .after-input:before {
    color: black;
    fill: var(--medium-gray);
    border-color: #e2e2e2;
}
.checkbox-group.disabled input[type=radio]:checked + .after-input:before {
    background-color: #00a335;
}

.checkbox-group.disabled input[type=checkbox]:checked + .after-input:before {
    background-color: #00a335;
    border-color: #00a335;
}
