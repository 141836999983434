.number-picker .icon svg path {
    fill: var(--itb-green);
}

.number-picker .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 25px;
    cursor: pointer;
    user-select: none;
}

.number-picker-up {
    transform: rotate(90deg)
}
.number-picker-down {
    transform: rotate(-90deg)
}

.number-picker-value {
    padding: 5px;
    background-color: rgba(0,0,0,.06);
    width: 30px;
    user-select: none;
}