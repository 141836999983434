.midgard-app {
    width: 100%;
    height: 100%;
}

.subtitle {
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0;
    color: #4a4a4a;
    color: var(--text-color-dark);
}

.clickable {
    cursor: pointer;
}