/* Shared */
.ui.button.google-auth__button, .glogin-again-button {
    min-width: 200px;
    padding: 12px 16px;
    background: #FFFFFF;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24);
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.22px;
    color: rgba(0,0,0,0.54);
    border: 1px solid lightgray;
}

.glogin-again-button {
    background-color: var(--itb-green);
    color: white;
    border: none;
}

google-auth__button:hover {
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
}

.google-auth__logo {
    display: inline-block;
    margin-right: 16px;
    height: 24px;
    width: 24px;
    line-height: 24px;
    vertical-align: top;
}
google-auth__button.disabled,
google-auth__button:disabled {
    background: #EEEEEE;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
}

.google-auth {
    position: fixed;
    left: 0px;
    bottom: 0px;
}

.glogin-box {
    width: 365px;
    height: 300px;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    position: relative;
    top: -30px;
    box-shadow: var(--default-shadow);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 15;
}

.glogin-box-title {
    position: absolute;
    top: 20px;
    font-size: 18px;
}

.glogin-explain {
    position: absolute;
    top: 60px;
    font-size: 14px;
}

.glogin-signed {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    min-height: 200px;
}