* {
  box-sizing: border-box;
}

html{
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  text-rendering: optimizeLegibility;
  font-stretch: 100%;
  color: var(--text-color);
  overflow: hidden !important;
  overflow-y: auto !important;
}

#root {
  width: 100%;
  height: 100%;
}

.ct-chart-pie {
  overflow: visible;
}
.ct-series-a {
  stroke: var(--itb-green);
}
.ct-series-a .ct-slice-pie {
  fill: var(--itb-green) !important;
}

.ct-series-b {
  stroke: var(--light-green);
}
.ct-series-b .ct-slice-pie {
  fill: var(--light-green) !important;
}

.ct-series-c {
  stroke: var(--bright-green);
}
.ct-series-c .ct-slice-pie {
  fill: var(--bright-green) !important;
}

