/*        body {
            background-color: #f8f9fa;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
        }*/
.container {
    text-align: center;
}

.result {
    margin-top: 20px;
    font-size: 20px;
}

.tr143-management-page {
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.tr143-management-page .subtitle {
    margin: 10px 0 20px 0;
}