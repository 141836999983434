#associate-device-page {
    max-width: 1000px;
    margin: 0 auto;
}

.helper {
    z-index: 9999;
    display: flex;
    justify-content: space-between;
}
.helper span {
    padding: 0;
}

.list {
    z-index: 1000;
}

.helpicon{
    vertical-align: middle;
    margin-left: 9px;
    padding-right: 10px;
}

.device-filter {
    display: flex;
    width: 100%;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
}
.device-filter .itb-input {
    margin-top: 10px;
}
.device-filter .itb-button-container {
    width: 140px;
}
.device-filter .itb-button-md {
    min-width: 130px;
}

.itb-button-container #btn-discovery {
    width: 20px;
}

.discovery {
    width: 20px;
    padding-left: 10px;
}

#associate-device-page .itb-modal {
    max-width: 500px;
}
