.lan-management-page{
    min-width: 1020px;
    max-width: 1500px;
    margin: 0 auto;
}

.lan-flex-container{
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.lan-dhcp {
    min-width: 350px;
    margin: 0px 0px 0 0;
}

.lan-ip {
    min-width: 350px;
    margin: 0 150px 0 0;
}

