.upload-fw-form, .mandatory-version-update-form{
    max-width: 400px;
}

.upload-fw-form{
    padding-top: 20px;
}

.adm-container {
    display: flex;
    max-width: 850px;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
}

.mandatory-update-box {
    min-width: 400px;
}

textarea.notification {
    width: 370px;
    height: 100px;
}