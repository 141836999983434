.maintenance-page {
    position: relative;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    width: 420px;
    padding-top: 40px;
}
@media screen and (min-width:890px) {
    .maintenance-page{
        width: fit-content;
    }
}

.maintenance-page .card {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    min-width: 400px;
    max-width: 400px;
    margin: 12px 12px;
    box-shadow: var(--default-shadow);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 240px;
}

.maintenance-page .card span{
    text-align: justify;
    padding: 0 20px;
}

.maintenance-page .card span.centered{
    text-align: center;
}

.device-uptime {
    position: absolute;
    top: 0;
    left: 20px;
}

.device-uptime .loading {
    position: relative;
    bottom: 5px;
    left: 5px;
}