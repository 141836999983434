.multi-field-search {
    display: flex;
    padding: 5px 18px 10px 20px;
    align-items: flex-end;
    position: relative;
    margin: 0 10px;
}

.multi-field-search .field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.multi-field-search .field label {
    font-weight: bold;
    padding-left: 6px;
}
.multi-field-search .field input {
    line-height: 38px;
    padding: 0 8px;
    border: 1px solid #ddd;
    outline: none;
    width: 100%;
    max-width: 160px;
}
.multi-field-search .field input.start {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.multi-field-search .field-search {
    height: 40px;
    border: none;
    outline: none;
    background-color: var(--itb-green);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 16px;
    color: white;
    fill: white;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 110px;
    cursor: pointer;
}
.field-search:hover {
    background-image: var(--dark-main-gradient)
}
.field-search:active {
    background-image: var(--light-main-gradient);
}

#clean-filters {
    position: absolute;
    text-decoration: underline;
    color: var(--itb-green);
    bottom: -15px;
    right: 20px;
    cursor: pointer;
}

#clean-filters:active {
    color: var(--light-green);
}