#management-wan {
    min-width: 1020px;
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 60px;
}

.wan-flex-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

#wan-configuration-form {
    min-width: 440px;
    padding: 0 20px;
}


#device-wan-statistics {
    padding: 20px;
    margin: 0;
    min-width: 525px;
}

.wan-info-cards {
    display: flex;
    flex-wrap: wrap;
}

.wan-card {
    flex: 1;
}

.wan-link {
    padding:10px 20px;
    text-align: right;
    text-decoration: underline;
    color: var(--itb-green);
    font-size: 15px;
    cursor: pointer;
}
.wan-link::before {
    content: '+';
    border: 1px solid var(--itb-green);
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    width: 10px;
    height: 10px;
    line-height: 10px;
    margin-right: 4px;
    position: relative;
    top: 1px;
}

.wan-link.cancel {
    color: var(--error-color);
}
.wan-link.cancel::before {
    content: '-';
    border-color: var(--error-color);
}

#delete-wan {
    width: 380px;
}
