.toast-container{
    box-sizing: border-box;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: calc(10px + var(--main-header-size));
    right: 0px;
    padding: 8px;
    z-index: 10;
}

.dismiss-all-toast .toast-content{
    padding-right: 0px;
    position: relative;
    float: left;
    height: 100%;
    width: 100%;
}

.toast-text {
    display: inline-block;
}

.txt-dismiss-all span {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 150ms;
    padding-left: 5px;
}

.txt-dismiss-all {
   display: inline-block; 
}

.txt-dismiss-all span:hover {
    opacity: 1;
}

