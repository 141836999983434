#profiles-page {
    max-width: 1800px;
    margin: 0 auto;
    padding: 20px 10px;
    height: 100%;
}


#profile-add-page form {
    margin: 0 auto;
}

#profiles-page .list-line-item {
    overflow: hidden;
    text-overflow: ellipsis;
}

.profiles-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 20px;
    height: calc(100% - 30px);
}

.no-managed {
    max-width: 400px;
    text-align: left;
    margin: 20px 0 30px 0;
    font-size: 13px;
    overflow: hidden;
    transition: height 200ms;
}

#profiles-page form {
    max-width: 400px;
}

.profile-add-button {
    position: relative;
    cursor: pointer;
    pointer-events: all;
    font-size: 14px;
    color: var(--itb-green);
    padding-left: 20px;
    text-align: left;
    width: 15%;
    margin-bottom: 4px;
}

.profile-add-button label {
    cursor: pointer;
}

.profile-add-icon {
    color: var(--itb-green);
    border-radius: 50%;
    border: 1.2px solid var(--itb-green);
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 10px 4px 10px 0px;
}

.section-divider {
    padding: 5px 0;
    border-bottom: 1px solid var(--light-gray);
    font-weight: bold;
    margin-bottom: 10px;
}

.profile-viewer {
    padding: 5px 0px 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}

.profile-viewer-title {
    font-size: 18px;
    font-weight: bold;
    padding: 5px 0;
    border-bottom: 1px solid var(--dark-gray);
    width: 100%;
    margin-left: 10px;
}

.profile-viewer section {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-width: 380px;
    max-width: 400px;
    margin: 10px;
    position: relative;
}

.profile-viewer section .profile-section-edit-button {
    fill: var(--gray);
    padding: 4px 4px 4px 20px;
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
    margin: 4px 0;
}

.mesh-info {
    max-width: 300px;
    text-align: left;
}

.tooltiptext {
    line-height: 1.0rem
}


.profile-section-title {
    padding: 2px 10px;
    border-bottom: 1px solid var(--light-gray);
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.profiles-scroll-area {
    height: 100%;
    flex: 1;
}

.profiles-scroll-area.profile-list {
    flex: 0.8;
}

.profiles-scroll-area.profile-viewer {
    flex: 1;
}

.profile-info-text {
    height: 100%;
    padding-top: 50px;
}

.dont-show {
    padding-top: 10px;
}

html {
    background-color: white;
}

.default-profile-info {
    height: 0;
    text-align: left;
    font-size: 13px;
    overflow: hidden;
    transition: height 200ms;
}

.default-profile-info.open {
    height: 58px;
}

.itb-input-edit-icon.disabled {
    fill: var(--gray);
    cursor: default
}

#copy-profile-form {
    position: relative;
    padding: 10px 0 30px 0;
}

#copy-name-create {
    position: absolute;
    right: 10px;
    bottom: 0px;
    width: 150px;
    min-width: unset;
}

#copy-name-cancel {
    position: absolute;
    left: 10px;
    bottom: 0px;
    width: 150px;
    min-width: unset;
}

.auto-apply-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.profile-search {
    width: calc(50% - 70px);
    margin-left: 10px;
    margin-bottom: -10px;
}

#profile-edit-confirm-page {
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    max-width: 680px;
    margin: 0 auto;
    text-align: justify;
}

#profile-edit-confirm-page .subtitle {
    text-align: center;
    font-size: 24px;
}

#profile-edit-confirm-page .loading {
    display: inline-flex;
    width: 50px;
}

#profile-edit-confirm-page .button-group {
    display: flex;
    margin-top: 40px;
}

.upgrade-selector {
    position: relative;
    max-width: 380px;
    height: 60px;
    padding: 12px;
    margin-bottom: 12px;
    cursor: pointer;
}

.upgrade-selector svg {
    fill: white;
    margin: 0 10px;
    cursor: pointer;
}

.upgrade-selector label span {
    color: white;
    cursor: pointer;
}

.upgrade-selector label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 2px;
    margin: 0 10px;
    background-color: var(--itb-green);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.upgrade-selector input {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    cursor: pointer !important;
}

.form-section-wifi-configuration {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.radio-cofiguration-2_4GHz {
    width: 50%;
}

.radio-cofiguration-5GHz {
    width: 50%;
}

.wireless-configuration {
    display: flex;
    flex-direction: row;
}

.form-section-radio-configuration {
    display: flex;
    flex-direction: row;
}

.form-section-wireless-configuration-dual {
    width: 100%;
}

.with-info {
    position: relative;
}

.with-info .info-popup {
    position: absolute;
    top: -2px;
    right: 12px;
}