#signup-page {
    max-width: 800px;
    margin: 0 auto;
}

#signup-page form {
    max-width: 400px;
    margin: 0 auto;
}

.signup-welcome {
    padding: 30px 0;
}

#signup-isp-info {
    word-wrap: break-word;
}