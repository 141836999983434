.router-button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: white;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .12);
}
.router-button:hover {
    background-color: #fafafa;
}

.router-button label {
    position: relative;
    top: 2px;
    text-align: left;
    font-size: 17px;
    color: #555;
    pointer-events: none;
}

.router-button label * {
    margin: 5px 0;
}

.router-button-description {
    font-size: 14px;
    color: #777;
}

