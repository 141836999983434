#update-page {
    margin: 0 auto;
    padding: 20px;
}
#update-page .tooltiptext {
    min-width: 320px;
}

#update-page .info-popup {
    z-index: 0;
}

.update-page-section {
    margin: 4px;
    background-color: white;
    flex: 1;
}

.update-page-section .list-header-item {
    white-space: normal;
}

.update-page-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.update-card {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    width: 45%;
    min-width: 620px;
}

#update-page .subtitle {
    padding-bottom: 0px;
    margin: 0 auto;
}
#update-page .list {
    padding-top: 5px;
}

#update-form {
    padding: 0 20px;
    margin: 0 auto;
}

.subtitle.with-extra-margin {
    margin: 20px 0;
}

.update-time, .update-time-window {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.update-time-window, .common-time-window {
    margin: 0 20px;
}
.update-time-picker .info-popup span {
    min-width: 420px;
}

.update-time-picker {
    display: flex;
    padding: 5px 0px;
    align-items: center;
    justify-content: center;
}

.update-time-picker label {
    font-weight: bold;
    padding-left: 2px;
    font-size: 16px;
}

.update-time-separator {
    font-weight: bold;
    padding: 0 4px;
}

.update-time-window span{
    padding: 0 15px;
    font-weight: bold;
}

.update-time-text {
    font-weight: bold;
    white-space: nowrap;
}

.update-actions {
    position: relative;
    width: 80px;
    height: 40px;
    outline: none;
    user-select: none;
    text-align: center;
    margin: 0 auto;
}
.update-actions button {
    border: none;
    cursor: pointer;
    padding: 0 4px;
    background-color: transparent;
}

.trial-period {
    white-space: pre-wrap;
}
.update-action {
    position: relative;
    font-size: 12px;
    color: var(--text-color);
    top: -2px;
}
/* .update-activate:hover svg{
    transform: scale(1.2);
} */
.update-activate svg{
    display: block;
    margin: 0 auto;
    position: relative;
    top: -4px;
}

.update-deactivate {
    width: 80px;
}
.update-deactivate:hover svg, .update-deactivate.img{
    transform: scale(1.2);
}
.update-deactivate svg, .update-deactivate img {
    margin: 0 auto;
    display: block;
    position: relative;
    top: -3px;
    fill: black;
}
.update-time-window {
    justify-content: center;
}

.update-no-devices {
    padding: 20px 0px;
}

.subtitle span {
    display: block;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    margin: 4px 0;
    color: var(--text-color);
    line-height: 1.5;
}

.autoupdate-device-list {
    position: relative;
    margin: 0 auto;
    margin-bottom: 20px;
}

.autoupdate-device-list .subtitle {
    text-align: left !important;
    margin-bottom: 45px !important;
}
#update-device-selector {
    display: none;
    position: absolute;
    top: -100px;
    left: 10px;
    background-color: white;
    min-width: 500px;
    z-index: 10;
    border-radius: 6px;
    padding: 15px;
}
#update-device-selector.open {
    display: block;
}

#update-device-selector .info-text {
    margin-top: -10px;
    margin-bottom: 5px;
}

#update-device-selector .close-icon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

#update-device-selector .close-icon svg{
    z-index: 0;
}

.update-device-item {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid var(--light-gray);
    cursor: pointer;
}
.update-device-item:hover {
    background-color: var(--light-gray);
}

#add-update-device {
    width: 200px;
    color: white;
    cursor: pointer;
    background-image: var(--main-gradient);
    padding: 8px;
    border-radius: 4px;
}

#add-update-device:before {
    content: '+';
    display: inline-block;
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    line-height: 15px;
    margin-right: 8px;
}


.update-device-item * {
    flex: 1;
    text-align: left;
    white-space: nowrap;
}
.update-device-item-selected-icon {
    flex: .2;
    text-align: center;
}
.update-device-item-selected-icon svg {
    fill: var(--itb-green);
    margin: 0 auto;
}

.update-device-search-list .info-text {
    margin-top: 80px;
}

#update-stats {
    padding: 20px;
}

.update-info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}


.model-stats-container {
    text-align: center;
    padding: 10px 0px ;
    margin-left: 10px;
}

@media screen and (max-width: 1815px){
    .model-stats-container {
        flex: 1
    }
}

.model-stats, .update-validation-info {
    position: relative;
    width: 330px;
    border: 1px solid var(--gray);
    border-radius: 8px;
    padding: 0px 10px 0px 10px;
    margin: 5px;
    margin: 0 auto;
}

.model-stats .subtitle {
    text-align: left;
    padding-bottom: 10px !important;
}

.model-stats .ct-chart {
    padding: 20px 10px;
}

.active-version {
    min-width: 200px;
    display: flex;
}
.model-stats .active-version img{
    margin-left: 10px;
}

.update-validation-info {
    margin-top: 10px;
    padding: 28px 20px 20px 20px;
}
.update-validation-info .title {
    color: var(--dark-gray);
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    left: 10px;
}

.update-validation-info .info-line span {
    white-space: nowrap;
}

.update-del-icon {
    fill: var(--gray);
    cursor: pointer;
    text-align: center;
}

.common-time-window {
    padding: 20px 0;
    font-size: 25px;
}

.with-info-group {
    display: flex;
    align-items: baseline;
}
.with-info-group .info-popup {
    margin-left: 15px;
    position: relative;
    top: 5px;
}
.with-info-group .info-popup span{
    min-width: 400px;
}

#update-validating-section {
    padding: 20px 0;
}
#update-validating-section .subtitle{
    margin-bottom: 20px;
}


.model-stats-chart-container {
    display: flex;
    max-height: 230px;
    margin-bottom: 10px;
}

.model-stats .donut-chart-legend div {
    float: left;
    margin-top: 10px;
}

.model-stats .donut-chart {
    float: left;
    margin-left: 10px;
}

.rv-discrete-color-legend {
    text-align: left;
}

.update-enable-field {
    width: 440px;
    margin: 40px auto 20px auto;
}

.update-disabled-info {
    width: 400px;
    margin: 40px auto;
    text-align: left;
}