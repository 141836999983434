.wlans{
    display: flex;
}

.lans{
    display:flex;
}

.lan-bind{
    display:block;
}
