.site-survey-card-container {
    width: 260px;
    height: 150px;
    padding: 15px;
    border-radius: 6px;
    box-shadow: var(--darker-shadow);
    border: 1px solid var(--light-grey);
    user-select: none;
    margin: 20px 20px 0 0;
    float: left;
}

.site-survey-card-container:hover {
    box-shadow: var(--green-shadow);
}

.site-survey-title{
    margin: 0 15px 0 0;
    height: 30px;
    text-align: center;
    font-family: 'Roboto', 'Open sans', sans-serif;
}

.info-container {
    display: flex;
}

.site-survey-main-info{
    height: 120px;
    width: 180px;
}

.sec-info-container{
    float: left;
    text-align: right;
    margin-left: 10px;
}

.site-survey-card-label {
    color: #7F8B8E;
    font-size: 13px;
    height: 20px;
    margin: auto;
    width: 100%;
    text-align: left;
}

.sec-info-container .site-survey-card-label{
    text-align: right;
}

.site-survey-card-title {
    font-size: 17px;
    color: #7F8B8E; 
}

.ss-hint {
    z-index: 10;
}