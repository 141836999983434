#wifi-page {
    width: fit-content;
    margin: 0 auto;
}
#router-devices-list {
    flex: 1;
    min-width: 1080px;
    max-width: 1080px;
    border: 1px solid var(--light-gray);
    border-radius: 6px;
    padding: 20px 10px;
    width: 100%;
    margin: 0 auto;

}

#management-wifi {
    max-width: 1200px;
    margin: 0 auto;
}

#wifi-management-page {
    position: relative;
    align-items: center;
    margin: 0 auto;
    min-width: 618px;
}

#wifi-management-page .dashboard-subtitle {
    padding-left: 0;
}

#management-wifi .flex-container {
    flex-wrap: wrap;
}

#wifi-management-info {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: 0 auto;
    flex-wrap: wrap;
    padding-bottom: 80px;
}

#wifi-management-info .info-line {
    margin: -10px 0 0 0;
}

#wifi-management-info .info-card {
    min-width: 450px !important;
}

#wifi-management-info .form-section {
    min-width: 400px;
    max-width: 400px;
    margin: 0 20px;
}

#wifi-management-info .loading{
    margin: 0 auto;
}

#wifi-management-info .itb-button-container {
    position: absolute;
    bottom: 0;
}

#wifi-management-info .info-line label{
    flex: 0;
    padding-left: 10px;
    margin-bottom: 10px;
}

#management-wifi {
    position: relative;
}

#site-survey-page {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    min-width: 1096px;
    margin-top: -20px;
}

#site-survey-button {
    position: absolute;
    top: -50px;
    width: 100px;
    right: -10px;
}

.wifi-ss-container {
    display: flex;
    width: fit-content;
    margin: 30px auto;
    position: relative;
}

#wifi-ss-back {
    position: absolute;
    top: 20;
    right: 50px;
    width: 100px;
}

.ss-hint {
    top: 10px !important;
    left: 660px !important;
}

.ss-nothing-found {
    width: 240px;
    text-align: center;
    margin: 40px auto;
}