.site-survey-chart{
    max-width: calc(100% - 20px);
    overflow-x: auto;
}

.ss-hint {
    background-color: white;
    position: absolute;
}

.ss-hint .site-survey-card-container{
    margin: 0 0 0 0;
}