.inmesh-management-page{
    min-width: 1020px;
    max-width: 1500px;
    margin: 0 auto;
}

.inmesh-flex-container{
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.imesh-sp {
    min-width: 350px;
    margin: 0px 0px 0 0;
    justify-content: center;
}

.imesh-ip {
    min-width: 350px;
    margin: 0 150px 0 0;
}

#mynetwork{
    display: flex;
    justify-content: center;
    padding:-10px;
}

.controller{
    display: flex;
}

.infoPopUp{
    margin-top: 20px;
    margin-left: 20px;
}

.tooltiptext{
    width: 300px;
}

